import React from "react";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="header-index about-page">
          <h2>About</h2>
          <p>
            PLoV (Pregnancy Loss Variants) is a curated database that integrates
            information from published studies concerning the contribution of
            genetic variants to the loss of euploid pregnancies. The current
            version of the database contains information from 47 studies of
            fetal and parental genomes, with a range of different outcomes
            (miscarriage, stillbirth, termination for fetal abnormality)
          </p>
          <h3>Citation</h3>
          <p>
            If you use PLoV in your research, please cite the original article:
          </p>
          <p>
            Maksiutenko E.M., Bezdvornykh I.V., Barbitoff Y.A., Nasykhova Y.A.,
            Glotov A.S. PLoV: a comprehensive database of genetic variants
            leading to pregnancy loss. Database. in press
          </p>
          <p>
            You can also cite our original review article concerned with the
            role of short genetic variants in pregnancy loss:
          </p>
          <p>
            Maksiutenko, E.M., Barbitoff, Y.A., Nasykhova, Y.A., Pachuliia,
            O.V., Lazareva, T.E., Bespalova, O.N., Glotov, A.S. The Landscape of
            Point Mutations in Human Protein Coding Genes Leading to Pregnancy
            Loss. Int. J. Mol. Sci. 2023, 24, 17572.
            <br />
            https://doi.org/10.3390/ijms242417572
          </p>

          <h3>Database structure</h3>
          <p>
            The database consists of four main tables: Variants, Cases, Studies
            and Observations. These tables provide detailed descriptive
            information about the variant and the settings and cases/families in
            which it was found. The variant table provides information about
            each variant (genomic location, transcript and protein-level
            consequence, rsID, allele frequency according to the Genome
            Aggregation Database (gnomAD) v. 4.1, as well as information about
            the corresponding gene and its molecular function. We also provide
            interpretations of variant pathogenicity according to the American
            College of Medical Genetics and Genomics (ACMG) criteria (the
            classifications were obtained using two independent web services,
            Franklin (https://franklin.genoox.com/clinical-db/home) and GeneBe
            (https://genebe.net/about)). ACMG criteria were also recorded as
            provided by GeneBe (unless criteria were clearly indicated in the
            original study). Furthermore, each variant was manually classified
            into confidence classes(see below).
          </p>
          <p>
            Each variant in the database has its own Variant ID which links the
            detected nucleotide substitution with the case (family) in which it
            was found. In the Cases table, we provide all significant
            information about the fetus, parents, observed phenotypes (including
            HPO terms, where available), gestational ages, and outcomes which
            were presented in the original articles. Each case has its own Case
            ID which is associated with the variants observed in this family
            using the Observations table, which allows researchers to track the
            genotype of the parents and fetus. From the Cases section with the
            help of Study ID, users can get information about specific study in
            which this case was described. The Study table contains data about
            the cohort and method used in the article, as well as related
            information that allows to find the article.
          </p>
          <h3>Criteria for selection of studies, cases, and variants</h3>
          <p>
            The main focus of PLoV are short genetic variants that cause
            different forms of pregnancy loss (miscarriage, stillbirth,
            termination for fetal abnormality) in euploid fetuses. Thus, the
            following criteria were employed during selection of studies and
            curation of data:
          </p>
          <ol>
            <li>
              Studies were included if they was aimed at discovery of short
              genetic variants (i.e., single-nucleotide substitutions or short
              indels) detected using targeted resequencing or
              whole-exome/whole-genome sequencing.
            </li>
            <li>
              Cases were included only if the pregnancy resulted in a loss of a
              fetus or early neonatal death. Live births with morphological
              abnormalities were excluded.
            </li>
            <li>
              Variants were recorded if they were clearly described, with
              information about genomic/transcript/protein position and
              substitution provided in the original article
            </li>
          </ol>
          <h3>Suggest changes/studies or submit your data</h3>
          <p>
            Please use the buttons on top of the website to report a problem in
            the data or submit your own cases and variants.{" "}
            <strong>
              If you want to suggest an article to be reviewed and added to the
              database
            </strong>
            , please provide us with the PMID or DOI of the study using the
            “Report a problem” button.
          </p>
          <h3>Assessment of Confidence Levels for Genetic Variants</h3>
          <p>
            Given that some of the variants reported in the original studies had
            low support for their causal role in pregnancy loss, we decided to
            manually classify each variant into three confidence categories
            based on the strength of supporting evidence. The criteria for
            inclusion into each group were as follows:
          </p>
          <p>
            <i>High confidence variants (highlighted with green background).</i>
            This group included variants with the strongest evidence of their
            causal role in pregnancy loss. These variants were required to be
            classified as pathogenic (P) or likely pathogenic (LP) by automated
            variant classificators and have either (1) fetal genotype
            information or (2) parental genotypes and a gestational age
            information available.
          </p>
          <p>
            <i>Low confidence variants (highlighted with red background)</i>.
            This group included variants that met one of the following criteria:
            (1) classified as benign (B) or likely benign (LB) by automated
            variant evaluation tools; (2) classified as a variant of uncertain
            significance (VUS) with no available fetal genotype data available;
            or (3) classified as VUS with parental genotype data are available,
            but no corresponding phenotype or gestational age information
            provided.
          </p>
          <p>
            <i>
              Intermediate confidence variants (highlighted with yellow
              background).
            </i>
            All variants not meeting the criteria for high or low confidence
            were put into this category.
          </p>

          {/*
          <p>
            PLoV is a database consisting of four main tables: Variants, Cases,
            Studies and Observations. Each of these tables provides detailed
            descriptive information about the variant and the settings and
            cases/families in which it was found. The main page provides
            information about nucleotide substitutions and the amino acid
            substitutions they lead to, as well as the gene and chromosome in
            which this SNP is located. Each variant has its own Variant ID which
            allows the user to link the detected nucleotide substitution with
            the case (family) in which it was found. In the Cases table, we
            tried to provide all significant information about the fetus,
            parents, observed phenotypes and pregnancy duration and results
            which were presented in the articles. Each case has its own Case ID
            which is associated with the Observations table (as well as Variant
            ID), which allows researchers to track the genotype of the parents
            and fetus. From the Cases section with the help of Study ID, users
            can get information about specific study in which this case was
            described. The Study table contains data about the cohort and method
            used in the article, as well as related information that allows to
            find the article.
          </p>

          <h3>Assessment of Confidence Levels for Genetic Variants</h3>
          <p>
            <i>Low Confidence:</i> Variants are assigned a low confidence level
            if they lack strong indicators of pathogenicity or sufficient
            supporting data. Specifically, this level is designated when:
          </p>
          <ul>
            <li>
              At least once variant is classified as benign (B) or likely benign
              (LB).
            </li>
            <li>
              The variant is classified as a variant of uncertain significance
              (VUS) with no available fetal genotype data.
            </li>
            <li>
              The variant is classified as VUS, and parental genotype data are
              available, but no corresponding phenotype or gestational age
              information is provided.
            </li>
          </ul>
          <p>
            <i>Intermediate Confidence:</i> The intermediate confidence level is
            assigned to variants with more supporting evidence than low
            confidence but still lacking complete data for a high-confidence
            designation. Criteria include:
          </p>

          <ul>
            <li>
              Variants classified as VUS with fetal genotype information
              available.
            </li>
            <li>
              VUS classifications with both parental genotype and phenotype
              information, with reported gestational age.
            </li>
            <li>
              Variants classified as pathogenic (P) or likely pathogenic (LP)
              without fetal genotype data and no available parental genotype.
            </li>
            <li>
              At least one P or LP classification, parental genotype data
              available, but no phenotype information (reported gestational age
              is highly relevant in such cases).
            </li>
          </ul>

          <p>
            <i>High Confidence:</i> This level is reserved for findings with the
            strongest supporting evidence. A variant is considered high
            confidence if:
          </p>

          <ul>
            <li>
              At least once variant is classified as P or LP, with corresponding
              fetal genotype data available.
            </li>
            <li>
              At least one P or LP variant is identified with parental genotype
              and a gestational age indicated in the phenotype.
            </li>
          </ul>
          */}
        </div>
      </>
    );
  }
}

export default AboutPage;
