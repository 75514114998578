import React from "react";
import Loader from "./Loader";
import AutocompleteRequest from "./AutocompleteRequest";
import { beforeSave } from "../parts/functions";
import Alert from "@mui/material/Alert";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTypes from "./DataTypes";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FileBrowser from "./FileBrowser";

import locale from "../i18n/locale";

function InputPassword({ onChange }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const params = {
    size: "small",
    variant: "outlined",
    label: "Сбросить пароль",
    onChange: onChange,
  };

  return (
    <TextField
      {...params}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.header = props.header || [];
    let s = {};
    this.header.map((h) => {
      s[h.field] = props.target ? props.target[h.field] : "";
      if (h.type == "date") s[h.field] = dayjs(s[h.field]);
    });
    this.state = { sample: { ...s, ...props.fixed } };
    this.state.error = false;
    if (props.target && props.target.tree) {
      props.target.tree.map((t) => {
        this.state.sample[`__tree_${t.id}`] =
          t.type == "date" ? dayjs(t.value) : t.value;
      });
    }
    this.loader = new Loader(this);
  }

  set(label, value) {
    this.setState((s) => {
      s.sample[label] = value;
      return { sample: s.sample };
    });
  }

  end(h) {
    return h.end ? (
      <InputAdornment position="end">
        <IconButton onClick={h.end} edge="end">
          <DeleteIcon />
        </IconButton>
      </InputAdornment>
    ) : (
      ""
    );
  }

  // Input types
  render_string(h) {
    let value = this.state.sample[h.field];
    if (value == null) value = "";
    h._params = {
      ...h._params,
      ...{
        size: "small",
        variant: "outlined",
        key: h.field,
        required: !h.optional,
        label: h.headerName ? h.headerName : h.field,
        value: value,
        onChange: (e) => this.set(h.field, e.target.value),
      },
    };
    return (
      <TextField
        {...h._params}
        type={h.input_type ? h.input_type : ""}
        InputProps={{ endAdornment: this.end(h) }}
      />
    );
  }
  render_number(h) {
    h.input_type = "number";
    return this.render_string(h);
  }
  render_text(h) {
    h._params = { multiline: true, maxRows: 20 };
    return this.render_string(h);
  }
  render_password(h) {
    return (
      <InputPassword
        h={h}
        onChange={(e) => this.set(h.field, e.target.value)}
      />
    );
  }
  render_file(h) {
    return (
      <FileBrowser
        label={h.headerName}
        onChange={(value) => this.set(h.field, value)}
        // refData
      />
    );
  }

  render_date(h) {
    return (
      <LocalizationProvider key={h.field} dateAdapter={AdapterDayjs}>
        <DateField
          size="small"
          required={!h.optional}
          label={h.headerName ? h.headerName : h.field}
          value={this.state.sample[h.field] || ""}
          format="DD.MM.YYYY"
          onChange={(value) => {
            this.set(h.field, value);
          }}
          InputProps={{ endAdornment: this.end(h) }}
        />
      </LocalizationProvider>
    );
  }
  render_foreign(h) {
    return (
      <AutocompleteRequest
        h={h}
        size="small"
        value={this.state.sample[h.field] || ""}
        onChange={(v) => this.set(h.field, v)}
        readOnly={this.props.fixed && this.props.fixed[h.field]}
      ></AutocompleteRequest>
    );
  }
  render_singleSelect(h) {
    let v = this.state.sample[h.field] || "";
    return (
      <TextField
        select
        size="small"
        label={h.headerName}
        defaultValue={v}
        value={v}
        onChange={(e) => this.set(h.field, e.target.value)}
        variant="outlined"
      >
        {h.valueOptions.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  render() {
    return (
      <>
        <DialogContent dividers={true}>
          <div tabIndex={-1}>
            <div
              className={
                "modal-body object-editor " +
                (this.props.read_only ? "read-only" : "")
              }
            >
              {this.header.map((h) => {
                if (h.field == "id" || h.type == "actions") return "";
                //console.log("this.props.read_only", this.props.read_only);

                if (this.props.read_only) {
                  if (h.type == "foreign") {
                    return "";
                  }
                  return (
                    <div key={h.field} className={`text-block`}>
                      <span className="label">
                        {h.headerName ? h.headerName : h.field}
                      </span>
                      <span className={`value field-${h.field}`}>
                        {this.state.sample[h.field]}
                      </span>
                    </div>
                  );
                }
                console.log("h.field", h.field);

                if (h.field.indexOf("__") != -1) return "";

                return (
                  <div key={h.field} className={`input-block input-${h.type}`}>
                    {this[`render_${h.type}`]
                      ? this[`render_${h.type}`](h)
                      : this.render_string(h)}
                  </div>
                );
              })}
            </div>
            <DataTypes that={this} />
            {this.relations ? this.relations : ""}
          </div>
        </DialogContent>
        {this.state.error ? (
          <Alert severity="error">
            An error occurred while filling the database. Check the correctness
            of the entered parameters
          </Alert>
        ) : (
          ""
        )}
        <DialogActions className={"modal-footer"}>
          <Button variant="outlined" onClick={() => this.props.close()}>
            {locale.close}
          </Button>
          {this.props.read_only ? (
            ""
          ) : (
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                if (this.state["loading:update"]) return;
                this.loader.get(
                  "update",
                  {
                    sample: beforeSave(this.state.sample),
                    table: this.props.table,
                  },
                  (url, data) => {
                    this.props.close();
                  },
                  (message) => {
                    this.setState({ error: message });
                  }
                );
              }}
            >
              {locale.save}
              {this.state["loading:samples/update"] ? " ..." : ""}
            </Button>
          )}
        </DialogActions>
      </>
    );
  }
}

export default Editor;
